"use client"

import Link from "next/link"
import { ShoppingCart, UserCircle } from "lucide-react"

import StoryblokImage from "@/components/storyblok/helper/StoryblokImage"

export const StarterHeaderClient = ({ blok, data }) => {
  return (
    <>
      <div className={"flex h-12 w-full items-center justify-center"}>
        <div className={"flex w-full max-w-7xl flex-row justify-between"}>
          <div className={"flex flex-row gap-x-2"}>
            <div className={"rounded-lg bg-red-600 px-4 py-2 text-white"}>
              Angebote
            </div>
            <div className={"rounded-lg bg-sky-800 px-4 py-2 text-white"}>
              Schulungen
            </div>
            <div className={"rounded-lg bg-sky-800 px-4 py-2 text-white"}>
              Kataloge
            </div>
          </div>

          <div className={"flex flex-row gap-x-2"}>
            <div className={"flex flex-row items-center justify-end gap-x-2"}>
              <UserCircle />
              <p className={"text-sm"}>Anmelden</p>
            </div>
            <div className={"flex flex-row items-center justify-end gap-x-2"}>
              <ShoppingCart />

              <p className={"text-sm"}>Warenkorb</p>
            </div>
          </div>
        </div>
      </div>

      <div className={"flex h-20 w-full items-center justify-center"}>
        <div
          className={
            "flex h-12 w-full max-w-7xl flex-row justify-start gap-x-2"
          }
        >
          <Link href={"/"} prefetch={false} aria-label={"Logo"}>
            <StoryblokImage
              src={blok.logo.filename}
              className="aspect-square h-full w-auto"
              alt={"Logo"}
              loading="eager"
            />
          </Link>

          <div className="mx-auto flex w-full overflow-hidden rounded-md border-2 border-blue-800 font-[sans-serif]">
            <input
              type="search"
              placeholder="Search Something..."
              className="w-full bg-white px-3 py-2 text-sm text-gray-600 outline-none"
            />
            <button
              type="button"
              aria-label="Search"
              className="flex items-center justify-center bg-sky-800 px-5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 192.904 192.904"
                width="16px"
                className="fill-white"
              >
                <path d="m190.707 180.101-47.078-47.077c11.702-14.072 18.752-32.142 18.752-51.831C162.381 36.423 125.959 0 81.191 0 36.422 0 0 36.423 0 81.193c0 44.767 36.422 81.187 81.191 81.187 19.688 0 37.759-7.049 51.831-18.751l47.079 47.078a7.474 7.474 0 0 0 5.303 2.197 7.498 7.498 0 0 0 5.303-12.803zM15 81.193C15 44.694 44.693 15 81.191 15c36.497 0 66.189 29.694 66.189 66.193 0 36.496-29.692 66.187-66.189 66.187C44.693 147.38 15 117.689 15 81.193z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className={"flex flex-row gap-x-2 overflow-hidden bg-white"}>
        {data?.map((item) => <p key={item}>{item}</p>)}
      </div>
    </>
  )
}
