import Image from "next/image"

interface StoryblokImageProps {
  src: string
  className?: string
  alt?: string
  loading?: "eager" | "lazy"
  fill?: boolean
  storyblokEditable?: any
}

const StoryblokImage = (props: StoryblokImageProps) => {
  const match = RegExp(/\/(\d+)x(\d+)\//).exec(props.src)
  const width = match ? Number(match[1]) : 0
  const height = match ? Number(match[2]) : 0

  // see also storyblok image service https://www.storyblok.com/docs/image-service/
  const imageSource = (() => {
    return props.src.toLowerCase().includes(".svg")
      ? props.src
      : props.src + "/m/"
  })()

  return (
    <Image
      {...props.storyblokEditable}
      loading={props.loading}
      src={imageSource}
      width={!props.fill ? width : undefined}
      height={!props.fill ? height : undefined}
      className={props.className}
      alt={props.alt ?? ""}
      fill={props.fill}
    />
  )
}

export default StoryblokImage
