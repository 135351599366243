"use client"

import React, { useState } from "react"
import { storyblokEditable } from "@storyblok/react/rsc"
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react"

import { cn } from "@/lib/utils"

function ImageSlider({ blok }) {
  const slides = blok.slides || []
  const [currentIndex, setCurrentIndex] = useState(0)

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1
    setCurrentIndex(newIndex)
  }

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1
    const newIndex = isLastSlide ? 0 : currentIndex + 1
    setCurrentIndex(newIndex)
  }

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex)
  }

  const timeoutRef = React.useRef(null)

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  React.useEffect(() => {
    resetTimeout()
    // @ts-ignore
    timeoutRef.current = setTimeout(() => nextSlide(), 5000)

    return () => {
      resetTimeout()
    }
  })

  return (
    <div
      {...storyblokEditable(blok)}
      className="group relative m-auto aspect-video w-full"
    >
      <div
        style={{ backgroundImage: `url(${slides[currentIndex].filename})` }}
        className="size-full rounded-2xl bg-cover bg-center duration-500"
      ></div>
      {/* Left Arrow */}
      <div className="absolute left-5 top-1/2 hidden -translate-x-0 -translate-y-1/2 cursor-pointer rounded-full bg-black/20 p-2 text-2xl text-white group-hover:block">
        <ChevronLeftIcon onClick={prevSlide} className="size-8" />
      </div>
      {/* Right Arrow */}
      <div className="absolute right-5 top-1/2 hidden -translate-x-0 -translate-y-1/2 cursor-pointer rounded-full bg-black/20 p-2 text-2xl text-white group-hover:block">
        <ChevronRightIcon onClick={nextSlide} className="size-8" />
      </div>
      <div className="left-1 top-4 flex justify-center py-2">
        {slides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className={cn(
              " m-1 size-3 cursor-pointer rounded-full bg-black text-2xl",
              {
                "scale-150": slideIndex === currentIndex,
              }
            )}
          ></div>
        ))}
      </div>
    </div>
  )
}

export default ImageSlider
