"use client"

import { storyblokEditable } from "@storyblok/react/rsc"

const MegaMenu = ({ blok }) => {
  return (
    <nav
      {...storyblokEditable(blok)}
      className="relative border-b-2 border-gray-300 bg-white text-gray-900"
    >
      <div className="mx-auto flex justify-between">
        <ul className="flex">
          <li className="group  hover:text-black">
            <a
              href="#"
              className="  relative block px-4 py-6 text-sm font-bold  hover:text-black"
            >
              World of spices
            </a>
            <div className="absolute z-50 mb-16 hidden bg-white p-6 shadow-xl group-hover:block sm:mb-0">
              <div className="mx-auto flex w-full flex-wrap justify-between">
                <div className="mb-8 w-full text-center text-black">
                  <h2 className="text-2xl font-bold">
                    Spices from around the world.
                  </h2>
                  <p>
                    Your tasty adventure to explore the world of spices starts
                    here...
                  </p>
                </div>
                <ul className="w-full border-b border-gray-600 px-4 py-6 sm:w-1/2 sm:border-r lg:w-1/4 lg:border-b-0 lg:pt-3">
                  <div className="flex items-center">
                    <svg
                      className="mb-3 mr-3 h-8 fill-current text-black"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M3 6c0-1.1.9-2 2-2h8l4-4h2v16h-2l-4-4H5a2 2 0 0 1-2-2H1V6h2zm8 9v5H8l-1.67-5H5v-2h8v2h-2z" />
                    </svg>
                    <h3 className="text-bold mb-2 text-xl font-bold text-black">
                      America
                    </h3>
                  </div>
                  <p className="text-sm text-gray-100">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <div className="flex items-center py-3">
                    <svg
                      className="h-6 fill-current pr-3 text-teal-300"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z" />
                    </svg>
                    <a
                      href="#"
                      className="bold border-b-2 border-teal-300 text-black hover:text-teal-900"
                    >
                      Find out more...
                    </a>
                  </div>
                </ul>
                <ul className="w-full border-b border-gray-600 px-4 py-6 sm:w-1/2 sm:border-r-0 lg:w-1/4 lg:border-b-0 lg:border-r lg:pt-3">
                  <div className="flex items-center">
                    <svg
                      className="mb-3 mr-3 h-8 fill-current text-black"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M4.13 12H4a2 2 0 1 0 1.8 1.11L7.86 10a2.03 2.03 0 0 0 .65-.07l1.55 1.55a2 2 0 1 0 3.72-.37L15.87 8H16a2 2 0 1 0-1.8-1.11L12.14 10a2.03 2.03 0 0 0-.65.07L9.93 8.52a2 2 0 1 0-3.72.37L4.13 12zM0 4c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4z" />
                    </svg>
                    <h3 className="text-bold mb-2 text-xl font-bold text-black">
                      Africa
                    </h3>
                  </div>
                  <p className="text-sm text-gray-100">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <div className="flex items-center py-3">
                    <svg
                      className="h-6 fill-current pr-3 text-teal-300"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z" />
                    </svg>
                    <a
                      href="#"
                      className="bold border-b-2 border-teal-300 text-black hover:text-teal-900"
                    >
                      Find out more...
                    </a>
                  </div>
                </ul>
                <ul className="w-full border-b border-gray-600 px-4 py-6 sm:w-1/2 sm:border-b-0 sm:border-r md:border-b-0 lg:w-1/4 lg:pt-3">
                  <div className="flex items-center">
                    <svg
                      className="mb-3 mr-3 h-8 fill-current text-black"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2 4v14h14v-6l2-2v10H0V2h10L8 4H2zm10.3-.3l4 4L8 16H4v-4l8.3-8.3zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z" />
                    </svg>
                    <h3 className="text-bold mb-2 text-xl font-bold text-black">
                      Asia
                    </h3>
                  </div>
                  <p className="text-sm text-gray-100">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <div className="flex items-center py-3">
                    <svg
                      className="h-6 fill-current pr-3 text-teal-300"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z" />
                    </svg>
                    <a
                      href="#"
                      className="bold border-b-2 border-teal-300 text-black hover:text-teal-900"
                    >
                      Find out more...
                    </a>
                  </div>
                </ul>
                <ul className="w-full border-gray-600 px-4 py-6 sm:w-1/2 lg:w-1/4 lg:pt-3">
                  <div className="flex items-center">
                    <svg
                      className="mb-3 mr-3 h-8 fill-current text-black"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9 12H1v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-6h-8v2H9v-2zm0-1H0V5c0-1.1.9-2 2-2h4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h4a2 2 0 0 1 2 2v6h-9V9H9v2zm3-8V2H8v1h4z" />
                    </svg>
                    <h3 className="text-bold mb-2 text-xl font-bold text-black">
                      Australia
                    </h3>
                  </div>
                  <p className="text-sm text-gray-100">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <div className="flex items-center py-3">
                    <svg
                      className="h-6 fill-current pr-3 text-teal-300"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z" />
                    </svg>
                    <a
                      href="#"
                      className="bold border-b-2 border-teal-300 text-black hover:text-teal-900"
                    >
                      Find out more...
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default MegaMenu
