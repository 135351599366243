import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ecube/opt/storyblok-nextjs-demo/source/node_modules/.pnpm/@storyblok+react@3.0.14_react-dom@18.3.1_react@18.3.1/node_modules/@storyblok/react/dist/bridge-loader.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ecube/opt/storyblok-nextjs-demo/source/node_modules/.pnpm/@storyblok+react@3.0.14_react-dom@18.3.1_react@18.3.1/node_modules/@storyblok/react/dist/story.mjs");
;
import(/* webpackMode: "eager" */ "/home/ecube/opt/storyblok-nextjs-demo/source/node_modules/.pnpm/next@14.2.8_@babel+core@7.24.6_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/ecube/opt/storyblok-nextjs-demo/source/node_modules/.pnpm/next@14.2.8_@babel+core@7.24.6_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/ecube/opt/storyblok-nextjs-demo/source/node_modules/.pnpm/next@14.2.8_@babel+core@7.24.6_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/ecube/opt/storyblok-nextjs-demo/source/node_modules/.pnpm/next@14.2.8_@babel+core@7.24.6_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/ecube/opt/storyblok-nextjs-demo/source/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ecube/opt/storyblok-nextjs-demo/source/src/components/storyblok/ecommerce/Carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ecube/opt/storyblok-nextjs-demo/source/src/components/storyblok/ImageSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ecube/opt/storyblok-nextjs-demo/source/src/components/storyblok/MegaMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StarterHeaderClient"] */ "/home/ecube/opt/storyblok-nextjs-demo/source/src/components/storyblok/starter/StarterHeaderClient.tsx");
